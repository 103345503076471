import React, { Suspense } from "react";
import { Outlet, Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { OrganisationCreatePage } from "@helpus/components/organisations/organisation-create";
import { LoadingIcon, Page } from "@helpus/ui";

import { AppLayout } from "./app-layout";
import { PrivateRoute } from "./private-route";

const LoginPage = React.lazy(() => import("@helpus/components/auth/login-page"));
// const RegisterPage = React.lazy(() => import("@helpus/components/auth/register-page"));
const HomePage = React.lazy(() => import("@helpus/components/home-page"));
const PatientProfile = React.lazy(
  () => import("@helpus/components/patients/patient-profile/patient-profile")
);
const AdvancedSearchPage = React.lazy(
  () => import("@helpus/components/advanced-search/advanced-search-page")
);

export const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<LoadingIcon fullPage />}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          {/* <Route path="/register" element={<RegisterPage />} /> */}
          <Route
            path="/organisations/*"
            element={
              <PrivateRoute>
                <Outlet />
              </PrivateRoute>
            }
          >
            <Route path="create" element={<OrganisationCreatePage />} />
          </Route>
          <Route
            path="/"
            element={
              <PrivateRoute requiresOrg>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route path="/patient/:code" element={<PatientProfile />} />
            <Route path="/search" element={<AdvancedSearchPage />} />
            <Route path="/" element={<HomePage />} />
          </Route>
          <Route path="*" element={<Page>404</Page>} />
        </Routes>
      </Suspense>
    </Router>
  );
};
