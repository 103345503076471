import { ThemeOptions, createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    tabletLg: true;
    desktopSm: true;
    desktop: true;
    desktopLg: true;
  }
}

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#507C6D",
      light: "#73968A",
      dark: "#38564C",
      contrastText: "#fff",
    },
    secondary: {
      main: "#A1C185",
      light: "#B3CD9D",
      dark: "#70875D",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    error: {
      main: "#B33951",
      light: "#C26073",
      dark: "#7D2738",
      contrastText: "#fff",
    },
    warning: {
      main: "#F4A259",
    },
    info: {
      main: "#6D435A",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Roboto",
    fontSize: 16,
    h1: {
      fontWeight: 400,
    },
    button: {
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 576,
      tabletLg: 768,
      desktopSm: 992,
      desktop: 1200,
      desktopLg: 1400,
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 8, // defaults to 4
  },
};

export default createTheme(themeOptions);
