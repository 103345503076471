import { Alert, AlertColor, Slide, SlideProps, Snackbar } from "@mui/material";

export type NotificationToastProps = {
  message: string;
  show: boolean;
  severity: AlertColor;
  onClose: () => void;
};

export const NotificationToast = ({ message, show, severity, onClose }: NotificationToastProps) => {
  return (
    <Snackbar
      open={show}
      autoHideDuration={3000}
      onClose={onClose}
      TransitionComponent={(props: SlideProps) => <Slide {...props} direction="down" />}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert variant="filled" severity={severity} onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};
