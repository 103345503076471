import React, { useEffect, useState } from "react";

import { useAuth } from "@helpus/api/use-auth";
import { LoadingIcon } from "@helpus/ui";

type PrivateRouteProps = {
  requiresOrg?: boolean;
  children: React.ReactNode;
};

export const PrivateRoute = ({ requiresOrg, children }: PrivateRouteProps) => {
  const [loading, setLoading] = useState(true);
  const { redirectByAuthStatus } = useAuth();

  useEffect(() => {
    redirectByAuthStatus(() => setLoading(false), undefined, requiresOrg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiresOrg]);

  return loading ? <LoadingIcon fullPage /> : children;
};
