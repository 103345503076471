import { Link } from "react-router-dom";

import { Box, styled } from "@mui/material";

export const NavbarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.palette.primary.dark,
  height: "60px",
  width: "100%",
  flexDirection: "row",
  alignItems: "center",
  transition: "all 0.2s",
}));

export const LeftContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "0 " + theme.spacing(2),
  width: "20%",
  height: "100%",
}));

export const Logo = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.contrastText,
}));

export const CenterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 " + theme.spacing(2),
  width: "60%",
  height: "100%",
}));

export const RightContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "0 " + theme.spacing(2),
  width: "20%",
  height: "100%",
}));
