import { Page } from "../sections/page";
import "./loading-icon.css";

type LoadingIconProps = {
  fullPage?: boolean;
};

export const LoadingIcon = ({ fullPage }: LoadingIconProps) => {
  const icon = <span className="loader" />;

  return fullPage ? <Page>{icon}</Page> : icon;
};
