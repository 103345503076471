import { Outlet } from "react-router-dom";

import { NavBar } from "@helpus/components/navbar/navbar";
import { NotificationToast } from "@helpus/ui/toast/notification-toast";

import { useNotifications } from "./store";

export const AppLayout = () => {
  const { message, isOpen, severity, onClose } = useNotifications();

  return (
    <>
      <NavBar />
      <NotificationToast message={message} show={isOpen} severity={severity} onClose={onClose} />
      <Outlet />
    </>
  );
};
