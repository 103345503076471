import React from "react";

import { Container, SxProps } from "@mui/material";

type PageProps = {
  children?: React.ReactNode;
  centered?: boolean;
  sx?: SxProps;
  bgcolor?: string;
};

export const Page = ({
  sx,
  centered = true,
  children,
  bgcolor = "background.default",
}: PageProps) => {
  return (
    <Container
      sx={{
        ...{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          px: { mobile: 0.5, tablet: 2 },
          py: 1,
          justifyContent: centered ? "center" : "initial",
          alignItems: "center",
          bgcolor,
        },
        ...sx,
      }}
    >
      {children}
    </Container>
  );
};
