import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { v4 as uuid } from "uuid";

import { Organisation, SBResponse, UserProfile, UserWithCredentials } from "@helpus/types";
import { useDataStore } from "@helpusapp/store";

import { supabase } from "./supabase";

export const useAuth = () => {
  const navigate = useNavigate();
  const {
    user: stateUser,
    setUser,
    removeUser,
    orgID: stateOrg,
    setOrg,
    removeOrg,
  } = useDataStore();

  /**
   * getAuthStatus returns a boolean to show if a user is logged in or not
   */
  const getAuthStatus = useCallback(async () => {
    const { data } = await supabase.auth.getSession();
    return !!data.session?.user;
  }, []);

  /**
   * getUser returns the user object from supabase
   */
  const getUser = useCallback(async () => {
    const { data, error } = await supabase.auth.getSession();
    if (error) throw error;
    return data.session?.user;
  }, []);

  /**
   * getUserProfile returns the user profile object from supabase
   */
  const getUserProfile = useCallback(async (userId: string) => {
    const { data, error }: SBResponse<UserProfile[]> = await supabase
      .from("profiles")
      .select("*")
      .eq("id", userId);
    if (error) throw error;
    if (!data || !data.length) throw new Error("No profile found");
    return data[0];
  }, []);

  /**
   * createOrg creates a new organisation and returns the organisation object
   */
  const createOrg = useCallback(
    async (organisationDetails: Organisation) => {
      const { organisation_name, email, address, phone } = organisationDetails;
      const org_id = uuid();
      const user = await getUser();
      if (!user) throw new Error("User not logged in");
      const { data, error } = await supabase
        .from("organisations")
        .insert([{ org_id, org_owner: user?.id, organisation_name, email, address, phone }])
        .select();
      if (error || !data) throw error;

      const org = data[0];
      setOrg(org.org_id);
      localStorage.setItem(`${user.id}_org`, org.org_id);
      return org;
    },
    [getUser, setOrg]
  );

  /**
   * getOrgs returns all the organisations a user is a member of
   */
  const getOrgs = useCallback(async (userId?: string) => {
    if (!userId) throw new Error("User not logged in");
    const { data, error } = await supabase
      .from("user_organisations")
      .select("*")
      .eq("user_id", userId);

    if (error) throw error;
    return data;
  }, []);

  /**
   * loadPrimaryOrg loads the main org from state or local storage or fetches it and sets it in state
   * It returns an error if there is no org found or if there is an error fetching the org
   */
  const loadPrimaryOrg = useCallback(
    async (userId?: string) => {
      if (!userId) return { error: "User not logged in" };

      let org_id = stateOrg || localStorage.getItem(`${userId}_org`);

      if (!org_id) {
        try {
          const orgs = await getOrgs(userId);
          if (!orgs || !orgs.length) return { error: "No organisations found" };

          org_id = orgs[0].org_id;
          localStorage.setItem(`${userId}_org`, org_id);
        } catch {
          return { error: "Error fetching organisations" };
        }
      }
      setOrg(org_id);
      return { error: "" };
    },
    [getOrgs, setOrg, stateOrg]
  );

  /**
   * login logs a user in and returns the route to redirect to.
   * It also gets the user profile and primary organisation and sets it in state
   */
  const login = useCallback(
    async (email: string, password: string) => {
      let returnRoute = "/";
      // login
      const {
        data: { user },
        error,
      } = await supabase.auth.signInWithPassword({ email, password });
      if (error || !user) throw error;
      // fetch profile
      try {
        const profile = await getUserProfile(user.id);
        setUser({ email, ...profile });
      } catch {
        await supabase.auth.signOut();
        throw new Error("Error fetching user profile");
      }

      // fetch org
      const { error: errorMsg } = await loadPrimaryOrg(user.id);
      if (errorMsg === "No organisations found") {
        returnRoute = "/organisations/create";
      } else if (errorMsg) {
        throw new Error(errorMsg);
      }

      return returnRoute;
    },
    [loadPrimaryOrg, getUserProfile, setUser]
  );

  /**
   * logout logs a user out and removes the user and org from state
   */
  const logout = useCallback(async () => {
    const { error } = await supabase.auth.signOut();
    removeUser();
    removeOrg();
    navigate("/login");
    if (error) throw error;
  }, [removeOrg, removeUser]);

  /**
   * signUp signs a user up and returns the user object
   * It also gets the user profile and sets it in state
   */
  const signUp = useCallback(
    async (userDetails: UserWithCredentials) => {
      const { email, password, first_name, last_name, phone } = userDetails;
      const {
        data: { user },
        error,
      } = await supabase.auth.signUp({
        email,
        password,
        options: { data: { first_name, last_name, phone } },
      });
      if (error) throw error;

      try {
        const profile = await getUserProfile(user?.id || "");
        setUser({ email, ...profile });
      } catch {
        throw new Error("No profile found");
      }

      return user;
    },
    [getUserProfile, setUser]
  );

  /**
   * redirectByAuthStatus redirects to the appropriate route based on the auth status
   * If the user is not logged in, it redirects to the login page (or the provided onAuthFail route)
   * If the user is logged in but has no org, it redirects to the relevant org page
   * If the user is logged in and has an org, it calls the provided onAuthSuccess function)
   */
  const redirectByAuthStatus = useCallback(
    async (onAuthSuccess: () => void, onAuthFail = () => navigate("/login"), checkOrg = false) => {
      const { data } = await supabase.auth.getSession();
      if (!data.session?.user) {
        onAuthFail();
        return;
      }

      if (!stateUser) {
        const profile = await getUserProfile(data.session.user.id);
        setUser({ email: data.session.user.email!, ...profile });
      }

      if (checkOrg) {
        const { error: errorMsg } = await loadPrimaryOrg(data.session.user.id);
        if (errorMsg === "No organisations found") {
          navigate("/organisations/create");
        } else if (errorMsg) {
          navigate("/organisation");
        }
      }

      if (onAuthSuccess) {
        onAuthSuccess();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadPrimaryOrg]
  );

  return {
    login,
    logout,
    signUp,
    getUser,
    getAuthStatus,
    getOrgs,
    createOrg,
    redirectByAuthStatus,
  };
};
