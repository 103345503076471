import { ThemeProvider, styled } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { theme } from "@helpus/ui";

import { AppRoutes } from "./routes";

const AppContainer = styled("div")`
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.background.default};
  height: 100%;
`;

export const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 5 * 60 * 1000, // 5 minutes
      },
    },
  });

  if (!import.meta.env.VITE_SUPABASE_KEY) {
    return <div>Error</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-AU">
        <QueryClientProvider client={queryClient}>
          <AppContainer>
            <AppRoutes />
          </AppContainer>
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
