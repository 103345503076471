import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Box, Button, Card, CardContent, TextField, Typography, styled } from "@mui/material";

import { useAuth } from "@helpus/api";
import { Organisation } from "@helpus/types";
import { CenteredBox, Page } from "@helpus/ui";

const FormContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "500px",
  padding: theme.spacing(2),
  justifyContent: "center",
}));

export const OrganisationCreatePage = () => {
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);

  const { register, handleSubmit } = useForm<Organisation>();
  const { createOrg } = useAuth();

  const onSubmit = handleSubmit(async (data) => {
    createOrg(data).then(() => navigate("/"));
  });

  return (
    <Page bgcolor="secondary.light">
      <Card elevation={4}>
        <CardContent>
          <Typography variant="h4" align="center">
            Welcome to Helpus!
          </Typography>
          <Typography variant="body1" align="center">
            Please create an organisation to get started
          </Typography>
          <FormContainer>
            <form onSubmit={onSubmit}>
              <TextField
                {...register("organisation_name")}
                label="Organisation name"
                variant="outlined"
                fullWidth
                margin="normal"
                required
              />
              <TextField
                {...register("email")}
                label="Organisation email"
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <TextField
                {...register("phone")}
                label="Phone number"
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <CenteredBox>
                <Button
                  size="medium"
                  sx={{ width: "15rem", m: 2 }}
                  type="submit"
                  variant="contained"
                >
                  Create Organisation
                </Button>
              </CenteredBox>
            </form>
          </FormContainer>
        </CardContent>
      </Card>
    </Page>
  );
};
