import { AlertColor } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid";
import { create } from "zustand";

import { User } from "@helpus/types";

interface NotificationsState {
  show: (message: string, severity: AlertColor) => void;
  message: string;
  isOpen: boolean;
  severity: AlertColor;
  onClose: () => void;
}

export const useNotifications = create<NotificationsState>((set) => ({
  message: "",
  isOpen: false,
  severity: "info",
  show: (message, severity) => {
    set({ message, severity, isOpen: true });
  },
  onClose: () => set({ isOpen: false }),
}));

interface PatientListState {
  searchQuery: string;
  setSearchQuery: (search: string) => void;
  sortModel: GridSortModel;
  setSortModel: (sortModel: GridSortModel) => void;
}

export const usePatientListStore = create<PatientListState>((set) => ({
  searchQuery: "",
  setSearchQuery: (search) => set({ searchQuery: search }),
  sortModel: [{ field: "modified_at", sort: "desc" }],
  setSortModel: (sortModel) => set({ sortModel }),
}));

interface DataStoreState {
  user?: User;
  orgID: string;
  setUser: (user?: User) => void;
  removeUser: () => void;
  setOrg: (orgID: string) => void;
  removeOrg: () => void;
}

export const useDataStore = create<DataStoreState>((set, get) => ({
  user: undefined,
  orgID: "",
  setUser: (user) => set({ user }),
  removeUser: () => set(() => ({ user: undefined })),
  setOrg: (orgID) => set({ orgID }),
  removeOrg: () => set(() => ({ orgID: undefined })),
}));

interface AppointmentsStoreState {
  expandedMap: Record<string, boolean>;
  expand: (id: string) => void;
  collapse: (id: string) => void;
  expandAll: (ids: string[]) => void;
  clearExpandedMap: () => void;
}

export const useAppointmentsStore = create<AppointmentsStoreState>((set, get) => ({
  expandedMap: {},
  expand: (id: string) => {
    set((state) => ({
      expandedMap: {
        ...state.expandedMap,
        [id]: true,
      },
    }));
  },
  collapse: (id: string) => {
    set((state) => ({
      expandedMap: {
        ...state.expandedMap,
        [id]: false,
      },
    }));
  },
  expandAll: (ids: string[]) => {
    set((state) => ({
      expandedMap: ids.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, state.expandedMap),
    }));
  },
  clearExpandedMap: () => {
    set(() => ({ expandedMap: {} }));
  },
}));
