import { Accept, useDropzone } from "react-dropzone";

import { Box, Typography, styled } from "@mui/material";

type FileInputProps = {
  description: string;
  // e.g. {"image/*": [".jpg", ".jpeg", ".png"]}
  accept: Accept;
  handleFileUpload: (files: File[]) => void;
  icon?: React.ReactNode;
  maxFiles?: number;
};

export const FileInput = ({
  accept,
  description,
  icon,
  handleFileUpload: onDrop,
  maxFiles = 1,
}: FileInputProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop,
    maxFiles,
  });

  return (
    <InputContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {icon}
      {isDragActive ? (
        <p>Drop the file here ...</p>
      ) : (
        <Typography variant="body1">{description}</Typography>
      )}
    </InputContainer>
  );
};

const InputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "30rem",
  height: "15rem",
  border: `2px dashed ${theme.palette.primary.dark}`,
  borderRadius: "4px",
  backgroundColor: theme.palette.background.paper,
  cursor: "pointer",
  textAlign: "center",
  margin: "1rem 0",
}));
