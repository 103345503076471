import { Button, Typography } from "@mui/material";

import { useAuth } from "@helpus/api";

import {
  CenterContainer,
  LeftContainer,
  Logo,
  NavbarContainer,
  RightContainer,
} from "./navbar-styled";

export const NavBar = () => {
  const { logout } = useAuth();

  return (
    <NavbarContainer>
      <LeftContainer>
        <Logo to="/">
          <Typography variant="h4">Helpus</Typography>
        </Logo>
      </LeftContainer>
      <CenterContainer />
      <RightContainer>
        <Button variant="contained" onClick={logout}>
          Logout
        </Button>
      </RightContainer>
    </NavbarContainer>
  );
};
