import { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, Paper, SxProps, Typography, styled } from "@mui/material";

type SectionProps = {
  children?: React.ReactNode;
  header?: string;
  collapsible?: boolean;
  sx?: SxProps;
  defaultState?: "collapsed" | "expanded";
};

export const Section = ({ sx, header, collapsible, children, defaultState }: SectionProps) => {
  const [collapsed, setCollapsed] = useState(collapsible);

  const handleCollapse = () => {
    if (!collapsible) {
      return;
    }
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (defaultState === "collapsed") {
      setCollapsed(true);
    } else if (defaultState === "expanded") {
      setCollapsed(false);
    }
  }, []);

  return (
    <Paper
      sx={{
        ...{
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.paper",
          m: 1,
          p: 2,
        },
        ...sx,
      }}
    >
      {header && (
        <Box
          id="section-header"
          onClick={handleCollapse}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            userSelect: "none",
            WebkitUserSelect: "none",
            cursor: collapsible ? "pointer" : "default",
          }}
        >
          <Typography variant="subtitle1">{header}</Typography>
          {collapsible && <StyledIcon className={collapsed ? "collapsed" : "expanded"} />}
        </Box>
      )}
      <Collapse in={!collapsible || !collapsed}>{children}</Collapse>
    </Paper>
  );
};

const StyledIcon = styled(ExpandMoreIcon)`
  &.expanded {
    transform: rotate(180deg);
  }
`;
