import { Box, SxProps } from "@mui/material";

type CenteredBoxProps = {
  children?: React.ReactNode;
  sx?: SxProps;
};

export const CenteredBox = ({ sx, children }: CenteredBoxProps) => {
  return (
    <Box
      sx={{
        ...{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
